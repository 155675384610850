body, html {
  height: 100%;
}

.node {
  cursor: pointer;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
}

#svgContainer {
  flex: 1 1 auto;
  overflow: auto;
}

.sidePanel {
  flex: 0 0 320px;
  overflow: auto;
  border-left: solid #ccc 1px;
}

.hidden {
  display: none;
}

p.comment {
  margin-top: 16px;
}

div.ui.error.message {
  margin: auto;
}

div.ui.errorPopup.message {
  left: 40px;
  bottom: 40px;
  position: fixed;
}

div.ui.card.intro {
  width: 770px;
  margin: auto;
}

.version {
  color: #888;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  background-image: url(topola.jpg);
  background-size: cover;
  background-position: 0% 20%;
  position: fixed;
  z-index: -100;
  filter: blur(8px) opacity(30%);
  -webkit-filter: blur(8px) opacity(30%);
}

div.zoom {
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  border-radius: 4px;
  position: fixed;
  margin: 10px;
  z-index: 10;
}

.zoom button {
  font-family: monospace;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  color: black;
  text-align: center;
  width: 26px;
  height: 26px;
  background-color: #fff;
  display: block;
  line-height: 26px;
  box-sizing: content-box;
  cursor: pointer;
  padding: 0px;
}

.zoom button:hover {
  background-color: #eee;
}

.zoom button:focus {
  outline: 0px;
}

.zoom-in {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 0px;
  border-bottom: 1px solid #ccc;
  border-left: 0px;
  border-right: 0px;
}

.zoom-out {
  border: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ui.loader.loading-more {
  position: absolute;
  top: auto;
  bottom: 0px;
  left: 25px;
}

.content .ui.image.blockImage {
  display: block;
}

.link-span {
  color: #4183c4;
  cursor: pointer;
}

.ui.menu .item>img:not(.ui).menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px
}

.ui.top.attached.menu {
  margin-top: 0px;
}
